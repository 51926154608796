import { useContext } from 'react';

import { UserPreferencesContext } from '../context/UserPreferencesContext';
import { CurrencyCode } from '../types/UserPreferences';

export function useCurrency(): [CurrencyCode] {
  const { preferences } = useContext(UserPreferencesContext);

  return [preferences.currency];
}
