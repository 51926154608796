import { SELECTED_PRODUCTS_BY_ID_QUERY } from '../../apollo/shopify/queries';
import { storefrontClient } from '../../apollo/shopify/storefrontClient';
import { getCountryByCurrency } from '../currency';
import { mapShopifyProductsToProducts } from '../product';
import { shopifyAdminIdToGraphqlId } from '../shopifyIds';

export const fetchProductsByIds = async (
  ids: string[],
  currency: CurrencyCode,
  shopifyIdType: ShopifyIdType,
) => {
  const {
    data: { nodes },
  } = await storefrontClient.query({
    query: SELECTED_PRODUCTS_BY_ID_QUERY,
    variables: {
      ids: ids.map((id) =>
        shopifyIdType === 'Admin' ? shopifyAdminIdToGraphqlId(id) : id,
      ),
      country: getCountryByCurrency(currency),
    },
    // Do not cache currency
    fetchPolicy: 'no-cache',
  });
  return mapShopifyProductsToProducts(
    nodes.filter((node: any) => node !== null),
  );
};
